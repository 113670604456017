<template>
  <Layout>
    <div class="container mt-4 py-5 text-justify">
      <div class="row pl-3">
        <div class="col-12">
          <p>
            El Instituto Estatal Electoral de Baja California Sur pone a tu disposición el
            Centro de Ayuda de Resultados Electorales, en donde se presentan temas relacionados
            al proceso de Elecciones Federales 2024:
            elecciones, votaciones, normatividad, PREP y cómputos.
            <br />
            Consulta cada apartado y conoce más sobre éstos.
          </p>
        </div>
      </div>

      <div class="row p-2 align-items-center">
        <div class="col-12 pl-4">
          <h4>
            Elecciones
          </h4>
        </div>
        <div class="col-12 col-md-6 mb-3 pt-3">
          <img src="../../assets/logo-ieebcs.png" alt="IEEBCS" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-12 col-md-6 mb-3">
          <p>
            En el <b>Proceso Local Electoral 2023 - 2024</b>,
            se elige 1 Gobernador o Gobernadora,
            5 Ayuntamientos
            16 Diputaciones por Mayoría Relativa y
            X Diputaciones por Representación Proporcional.
          </p>

          <!-- <router-link to="/centro-de-ayuda/elecciones">
            <b>
              Ver más
            </b>
          </router-link> -->
        </div>
      </div>

      <div class="row pt-3 mt-3 seccion-color align-items-center">
        <div class="col-12 mb-4">
          <h4>
            Votaciones
          </h4>
        </div>

        <div class="col-12 col-md-6 mb-3">
          <p>
            En las Elecciones Locales 2023 - 2024, se lleva a cabo la suma y
            distribución de los votos por Partidos Políticos, Coaliciones,
            Candidaturas Comunes y Candidaturas Independientes.
          </p>

          <router-link to="/centro-de-ayuda/votaciones/votos-coalicion">
            <b>
              Ver más
            </b>
          </router-link>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <InicioVotaciones class="mx-auto img-fluid d-block" />
        </div>
      </div>

      <div class="row p-3 align-items-center">
        <div class="col-12 mb-3 col-md-6">
          <Normatividad class="mx-auto d-block img-fluid" />
        </div>
        <div class="col-12 mb-3 col-md-6">
          <h4 class="mb-4">Normativad</h4>
          <p>
            El marco legal en el que se sustentan las Elecciones Locales 2023 - 2024,
            garantiza la seguridad, transparencia, confiabilidad,
            credibilidad e integridad del proceso.
          </p>

          <!-- <router-link to="/centro-de-ayuda/normatividad">
            <b>
              Ver más
            </b>
          </router-link> -->
        </div>
      </div>

      <div class="row p-3 seccion-color align-items-center">
        <div class="col-12 col-md-6 mb-3">
          <h4 class="mb-4">
            Programa de Resultados Electorales Preliminares (PREP)
          </h4>
          <p>
            Es el mecanismo de información de los resultados electorales
            preliminares, no definitivos,donde se capturan, digitalizan,
            publican y cotejan los datos asentados en las Actas de Escrutinio y Cómputo.
          </p>

          <!-- <router-link to="/">
            <b>
              Ver más
            </b>
          </router-link> -->
        </div>
        <div class="col-12 col-md-6 mb-3">
          <InicioPrep class="mx-auto img-fluid d-block" />
        </div>
      </div>

      <div class="row p-3 align-items-center">
        <div class="col-12 col-md-6 mb-3">
          <InicioComputo class="mx-auto img-fluid d-block" />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <h4 class="mb-4">
            Cómputos
          </h4>
          <p>
            Consisten en la suma de los votos contenidos en las Actas de Escrutinio y Cómputo.
            Los resultados definitivos se empiezan a dar a conocer a partir del miércoles
            posterior a la Jornada Electoral, hasta que se terminen de sumar todas las Actas.
          </p>

          <router-link to="/">
            <b>
              Ver más
            </b>
          </router-link>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5 my-3 ">
      <div class="row">
        <div class="col-12">
          <p class="text-center">
            El contenido y materiales que se muestran, son de carácter informativo y de difusión,
            referentes al Programa de Resultados Electorales Preliminares y
            Cómputos Distritales 2024. <br />

            Alterar la información y/o el contenido total o parcial de este sitio es delito federal.
          </p>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style lang="scss" scoped>


</style>
<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';
import InicioVotaciones from '@/components/CentroDeAyuda/InicioVotaciones.vue';
import Normatividad from '@/components/CentroDeAyuda/Normatividad.vue';
import InicioPrep from '@/components/CentroDeAyuda/InicioPrep.vue';
import InicioComputo from '@/components/CentroDeAyuda/InicioComputo.vue';

export default {
  name: 'CentroDeAyuda',
  components: {
    Layout,
    InicioVotaciones,
    Normatividad,
    InicioPrep,
    InicioComputo,
  },
};
</script>
